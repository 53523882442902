window.addEventListener('load', () => {
  const header = document.getElementById('header');

  const menu = document.getElementById('menu');
  const menuLinks = document.querySelectorAll('#menu a');
  const menuOpenBtn = document.getElementById('menu-btn-open');

  setPageTopOffset();

  menuOpenBtn.addEventListener('click', () => {
    menu.classList.toggle('menu--opened');
    header.classList.toggle('header--menu-opened');
    if (document.documentElement.style.overflow === 'hidden') {
      document.documentElement.style.overflow = '';
    } else {
        document.documentElement.style.overflow = 'hidden';
    }
  });

  if(document.body.classList.contains('page-id-164')){
    menuLinks.forEach(function (link) {
      link.addEventListener('click', function (event) {
          if (this.getAttribute('href').indexOf('#news') !== -1) {
            menu.classList.toggle('menu--opened');
            header.classList.toggle('header--menu-opened');
            document.documentElement.style.overflow = '';
          }
      });
    });
  }

  function setPageTopOffset() {
    if (header) {
      const body = document.body;

      if (!header.classList.contains('bg-transparent')) {
        document.body.style.paddingTop = `${header.clientHeight}px`;
      }
    }
  }

  document.addEventListener('resize', setPageTopOffset);
  document.addEventListener('scroll', () => {
    if (window.scrollY > 20 && header.classList && header.classList.contains('transparent-header')) {
      header.classList.add('header--scrolled');
    } else {
      header.classList.remove('header--scrolled');
    }
  });
});
